<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
                <h3 class="mb-0">View Profile</h3>
              </div>

            <div class="card-body">

              <div class="row justify-content-md-center">
                <div class="col-lg-8 col-md-12">

                  <el-form ref="customerForm" :model="customerForm" label-width="150px" :label-position="labelPosition">
                    <h3>Information</h3>
                   <el-form-item label="Member Code">
                      <el-input v-model="customerForm.membercode" readonly class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="First Name">
                      <el-input v-model="customerForm.personal.first_name" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Last Name">
                      <el-input v-model="customerForm.personal.last_name" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                   <el-form-item label="Date of Birth">
                      <el-input v-model="customerForm.personal.dateofbirth" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Address">
                      <el-input v-model="customerForm.personal.address" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="City">
                      <el-input v-model="customerForm.personal.city" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>
                
                    <el-form-item label="Zip Code">
                      <el-input v-model="customerForm.personal.zipcode" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <!-- <el-form-item label="Telephone">
                      <el-input v-model="customerForm.personal.telephone" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item> -->
                    <el-form-item label="Telephone" prop="telephone">
                      <vue-tel-input v-model="customerForm.personal.telephone" :autoDefaultCountry="false" :inputOptions="{'readonly': readonly}" class="form-control-alternative">
                        <template slot="arrow-icon">
                          <span class="vti__dropdown-arrow">▼</span>
                        </template>
                      </vue-tel-input>
                    </el-form-item>

                   <el-form-item label="Email">
                      <el-input v-model="customerForm.personal.email" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Employed">
                      <el-switch v-model="customerForm.personal.employed" :disabled="!readonly"></el-switch>
                    </el-form-item>

                    <el-form-item label="Occupation">
                      <el-input v-model="customerForm.personal.occupation" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Salary">
                      <el-input v-model="customerForm.personal.salary" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                  </el-form>
                </div>
              </div>
          
            </div>
            <div class="card-footer">
              <div class="row justify-content-xl-center">
                <div class="col-xl-12 text-center">
                   <el-button @click="cancelChange()" v-if="!readonly">Cancel</el-button>
                    <el-button type="success" @click="requestChange()" v-loading="submiting" 
                    :disabled="submiting" v-if="readonly">Request Change</el-button>
                    <el-button type="primary" @click="onSubmit()" v-loading="submiting" 
                    :disabled="submiting" v-if="!readonly">Submit</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
              <div class="card-body">
                <Shortcut/>
              </div> 
          </div>

        </div>
      </div>
    </div>
    <PinDialog ref="pincodedialog"  @checked="pincodeChecked"/>
  </div>
</template>
<script>
import CustomerApi from '@/api/customer'
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import Shortcut from '@/components/MenuShortcut.vue'
export default {
  components: {
    PinDialog,
    Shortcut
  },
  computed:{
    plusuid(){
      return this.$store.getters.plusuid
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
  },
  data(){
    return {
      submiting: false,
      loading: false,
      isPincode: false,
      readonly: true,
      customerForm: {
        company: null,
        customer_type: "",
        membercode: "",
        personal: {
          address: "",
          city: "",
          dateofbirth: "",
          email: "",
          employed: true,
          first_name: "",
          last_name: "",
          occupation: "",
          salary: 0,
          telephone: "",
          zipcode: ""
        }
      }
    }
  },
  methods:{
    getProfile(){
      this.loading = true;
      CustomerApi.viewProfile().then(({result, data})=>{
        this.loading = false
        if(result){
          this.customerForm.customer_type = data.customer_type
          this.customerForm.membercode = data.membercode
          this.customerForm.personal.address = data.personal.address
          this.customerForm.personal.city = data.personal.city
          this.customerForm.personal.dateofbirth = data.personal.dateofbirth
          this.customerForm.personal.email = data.personal.email
          this.customerForm.personal.employed = data.personal.employed ? true : false,
          this.customerForm.personal.first_name = data.personal.first_name
          this.customerForm.personal.last_name = data.personal.last_name
          this.customerForm.personal.occupation = data.personal.occupation
          this.customerForm.personal.salary = data.personal.salary
          this.customerForm.personal.telephone = data.personal.telephone
          this.customerForm.personal.zipcode = data.personal.zipcode

        }
      })
    },
    pincodeChecked(pincode){
      this.isPincode = pincode
      if(!pincode){
        this.submiting = false
      }else{
        this.onSubmit()
      }
    },
    onSubmit(){
      this.submiting = true
      let formData = JSON.parse(JSON.stringify(this.customerForm))
      formData.personal.telephone = formData.personal.telephone.replace(/\s/g, '')
      
      if(!this.isPincode && this.isMobile){
        this.$refs.pincodedialog.show('profile-update')
        return false;
      }
      CustomerApi.updateProfile(formData).then(({result, data, message})=>{
        this.submiting = false
        if(result){ 
          if(data.scapending){
            this.socketSubscription()
          }else{
            this.$swal(`Message`, 'Request Profile update success', 'success').then(()=>{
              this.cancelChange()
            })
          }
        }else{
          this.$swal('Message', message, 'error')
        }
      })
    },
    requestChange(){
      this.readonly = false
    },
    cancelChange(){
      this.readonly = true
    },
    socketSubscription(){
      this.$socket.connect()
      this.$swalTimer('Request Profile Pending', 'Please confirm Request Profile from your mobile device', 'warning')
      this.$swal.showLoading();
      this.sockets.subscribe('profile-update:'+ this.plusuid, (data)=> {
        this.$swal.close()
        this.$socket.disconnect()
    
          if(data.result){
            this.$swal.fire({
              icon: 'success',
              title: 'Request Profile update success',
              showConfirmButton: false
            })
            setTimeout(() => {
              this.cancelChange()
              this.$swal.close()
            }, 2000);
          }else{
            this.$swal('Message', data.message, 'error')
          }
        })
    },

  },
  created(){
    this.getProfile()
  }
};
</script>
<style></style>
