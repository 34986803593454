<template>
  <div>
    <profile-personal v-if="!isBusiness"/>
    <profile-business v-if="isBusiness"/>
  </div>
</template>
<script>
import ProfilePersonal from './ProfilePersonal.vue'
import ProfileBusiness from './ProfileBusiness.vue'
export default {
  components:{
    ProfilePersonal,
    ProfileBusiness
  },
  computed: {
    isBusiness(){
      return this.$store.getters.business
    }
  },
};
</script>
<style></style>
