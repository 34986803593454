<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-9 col-md-12">
          <div class="card mb-3">
            <div class="card-header">
                <h3 class="mb-0">View Profile</h3>
              </div>

            <div class="card-body" v-loading="loading">

              <div class="row justify-content-md-center">
                <div class="col-lg-8 col-md-12">

                  <el-form ref="customerForm" :model="customerForm" label-width="180px" :label-position="labelPosition">
                    <h3>Business Information</h3>
                   <el-form-item label="Member Code" prop="membercode">
                      <el-input v-model="customerForm.membercode" readonly placeholder="Member Code" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Business Name" prop="company.business_name">
                      <el-input v-model="customerForm.company.business_name" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Type of Business" prop="company.business_type">
                      <el-input v-model="customerForm.company.business_type" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>
                   <el-form-item label="Trading name" prop="company.business_trading_name">
                      <el-input v-model="customerForm.company.business_trading_name" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Financial Business" prop="company.business_is_financial">
                      <el-switch v-model="customerForm.company.business_is_financial" :disabled="readonly"></el-switch>
                    </el-form-item>

                    <el-form-item label="Business Address" prop="company.business_address">
                      <el-input v-model="customerForm.company.business_address" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>
                
                    <el-form-item label="Zip Code" prop="company.business_zipcode">
                      <el-input v-model="customerForm.company.business_zipcode" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Nature of Business" prop="company.business_natureof">
                      <el-input v-model="customerForm.company.business_natureof" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>
                   <el-form-item label="Company number" prop="company.business_company_number">
                      <el-input v-model="customerForm.company.business_company_number" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Website" prop="company.business_website">
                      <el-input v-model="customerForm.company.business_website" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Expect Transaction Activity" prop="company.business_expect_transaction_activity">
                      <el-input v-model="customerForm.company.business_expect_transaction_activity" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <h3>Director Information</h3>
                    
                    <el-form-item label="Director Name" prop="company.director_name">
                      <el-input v-model="customerForm.company.director_name" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Share Percentage" prop="company.director_sharepercentage">
                      <el-input v-model="customerForm.company.director_sharepercentage" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                   <el-form-item label="Date of Birth" prop="company.director_dateofbirth">
                      <el-input v-model="customerForm.company.director_dateofbirth" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Address" prop="company.director_address">
                      <el-input v-model="customerForm.company.director_address" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <el-form-item label="Zip Code" prop="company.director_zipcode">
                      <el-input v-model="customerForm.company.director_zipcode" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>

                    <!-- <el-form-item label="Telephone" prop="company.director_telephone">
                      <el-input v-model="customerForm.company.director_telephone" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item> -->
                    
                    <el-form-item label="Telephone" prop="telephone">
                      <vue-tel-input v-model="customerForm.company.director_telephone" :autoDefaultCountry="false" :inputOptions="{'readonly': readonly}" class="form-control-alternative">
                        <template slot="arrow-icon">
                          <span class="vti__dropdown-arrow">▼</span>
                        </template>
                      </vue-tel-input>
                    </el-form-item>

                    <el-form-item label="Email" prop="company.director_email">
                      <el-input v-model="customerForm.company.director_email" :readonly="readonly" class="form-control-alternative"></el-input>
                    </el-form-item>
                    
                  </el-form>
                </div>
              </div>
              <div class="card-footer">
                <div class="row justify-content-xl-center">
                  <div class="col-xl-12 text-center">
                    <el-button @click="cancelChange()" v-if="!readonly">Cancel</el-button>
                    <el-button type="success" @click="requestChange()" v-loading="submiting" 
                    :disabled="submiting" v-if="readonly">Request Change</el-button>
                    <el-button type="primary" @click="onSubmit()" v-loading="submiting" 
                    :disabled="submiting" v-if="!readonly">Submit</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-12">
          <div class="card mb-3">
              <div class="card-body">
                <Shortcut/>
              </div> 
          </div>

        </div>
      </div>
    </div>
    <PinDialog ref="pincodedialog"  @checked="pincodeChecked"/>
  </div>
</template>
<script>
import CustomerApi from '@/api/customer'
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import Shortcut from '@/components/MenuShortcut.vue'
export default {
  components: {
    PinDialog,
    Shortcut
  },
  computed:{
    plusuid(){
      return this.$store.getters.plusuid
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
  },
  data(){
    return {
      submiting: false,
      loading: false,
      isPincode: false,
      readonly: true,
      customerForm: {
        company:{
          business_address: "",
          business_company_number: "",
          business_expect_transaction_activity: "",
          business_natureof: "",
          business_website: "",
          business_zipcode: "",
          business_is_financial: false,
          business_trading_name: "",
          business_type: "",
          business_name: "",
          director_address: "",
          director_dateofbirth: "",
          director_email: "",
          director_name: " ",
          director_sharepercentage: "",
          director_telephone: "",
          director_zipcode: ""
        },
        customer_type: "",
        membercode: "",
        personal: null
      }
    }
  },
  methods:{
    getProfile(){
      this.loading = true;
      CustomerApi.viewProfile().then(({result, data})=>{
        this.loading = false
        if(result){
          this.customerForm.customer_type = data.customer_type
          this.customerForm.membercode = data.membercode
          this.customerForm.company.business_address = data.company.business_address
          this.customerForm.company.business_company_number = data.company.business_company_number
          this.customerForm.company.business_expect_transaction_activity = data.company.business_expect_transaction_activity
          this.customerForm.company.business_natureof = data.company.business_natureof
          this.customerForm.company.business_website = data.company.business_website
          this.customerForm.company.business_zipcode = data.company.business_zipcode
          this.customerForm.company.business_is_financial = data.company.business_is_financial ? true : false
          this.customerForm.company.business_trading_name = data.company.business_trading_name
          this.customerForm.company.business_type = data.company.business_type
          this.customerForm.company.business_name = data.company.business_name
          this.customerForm.company.director_address = data.company.director_address
          this.customerForm.company.director_dateofbirth = data.company.director_dateofbirth
          this.customerForm.company.director_email = data.company.director_email
          this.customerForm.company.director_name = data.company.director_name
          this.customerForm.company.director_sharepercentage = data.company.director_sharepercentage
          this.customerForm.company.director_telephone = data.company.director_telephone
          this.customerForm.company.director_zipcode = data.company.director_zipcode
        }
      })
    },
    pincodeChecked(pincode){
      this.isPincode = pincode
      if(!pincode){
        this.submiting = false
      }else{
        this.onSubmit()
      }
    },
    onSubmit(){
      this.submiting = true
      let formData = JSON.parse(JSON.stringify(this.customerForm))
      formData.company.director_telephone = formData.company.director_telephone.replace(/\s/g, '')
      
      if(!this.isPincode && this.isMobile){
        this.$refs.pincodedialog.show('profile-update')
        return false;
      }
      CustomerApi.updateProfile(formData).then(({result, data, message})=>{
        this.submiting = false
        if(result){ 
          if(data.scapending){
            this.socketSubscription()
          }else{
            this.$swal(`Message`, 'Request Profile update success', 'success').then(()=>{
              this.cancelChange()
            })
          }
        }else{
          this.$swal('Message', message, 'error')
        }
      })
    },
    requestChange(){
      this.readonly = false
    },
    cancelChange(){
      this.readonly = true
    },
    socketSubscription(){
      this.$socket.connect()
      this.$swalTimer('Request Profile update Pending', 'Please confirm Request Profile update from your mobile device', 'warning')
      this.$swal.showLoading();
      this.sockets.subscribe('profile-update:'+ this.plusuid, (data)=> {
        this.$swal.close()
        this.$socket.disconnect()
    
          if(data.result){
            this.$swal.fire({
              icon: 'success',
              title: 'Request Profile update success',
              showConfirmButton: false
            })
            setTimeout(() => {
              this.cancelChange()
              this.$swal.close()
            }, 2000);
          }else{
            this.$swal('Message', data.message, 'error')
          }
        })
    },

  },
  created(){
    this.getProfile()
  }
};
</script>
<style></style>
